import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import { env } from '../shared/config';

const baseUrl = env.API_URL;

export const baseQuery = (basePath: string) =>
  fetchBaseQuery({
    baseUrl: `${baseUrl}/${basePath}`,
    prepareHeaders: (headers) => {
      const authToken = localStorage.getItem('auth');

      if (authToken) {
        headers.set('authorization', `Bearer ${authToken}`);
      }

      return headers;
    },
  });
